import 'owl.carousel.es6';

import './LogoSlider.scss';

const LogoSlider = () => {
    const autoplay = 5000;

    const prev = '<img class="logo-slider__nav img-responsive" src="/images/icons/nav-left.svg">';
    const next = '<img class="logo-slider__nav img-responsive" src="/images/icons/nav-right.svg">';

    $(document).ready(function () {
        $('.logo-slider__wrapper').owlCarousel({
            loop: true,
            margin: 0,
            animateIn: 'fadeIn',
            animateOut: 'fadeOut',
            autoplayTimeout: autoplay,
            mouseDrag: true,
           // autoplayHoverPause: true,
            autoplay: true,
            responsiveClass: true,
            navText: [prev, next],
            dots: false,
            nav: true,
            responsive: {
                0: {
                    items: 1,
                },
                576: {
                    items: 2,
                },
                768: {
                    items: 3,
                },
                992: {
                    items: 5,
                },
            },
        });
    });
};

export default LogoSlider;
