import Init from '../../../extendable/Init';
import PageHeader from '../../../components/PageHeader/PageHeader';
import Join from '../../../components/Join/Join';
import ArticleBox from '../../../components/ArticleBox/ArticleBox';

import './NewsList.scss';

const NewsList = () => {
};

Init(NewsList);
