import AOS from 'aos';
import 'jquery-colorbox';
import '../resources/scss/styles/main.scss';

import topMenu from '../components/Navs/TopMenu/TopMenu';
import topBar from '../components/TopBar/TopBar';
import Footer from '../components/Footer/Footer';
import Radio from '../components/Radio/Radio';
import ContactForm from '../components/ContactForm/ContactForm';
import ContactWidget from "../components/ContactWidget/ContactWidget";
import Alert from "../resources/js/core/Alert/Alert";

const fadeLoader = () =>
	setTimeout(() => {
		document.getElementById('preloader').classList.add('closed');
		AOS.init({
			startEvent: 'DOMContentLoaded',
			debounceDelay: 50, // the delay on debounce used while resizing window (advanced)
			throttleDelay: 99, // the delay on throttle used while scrolling the page (advanced)
			anchorPlacement: 'top-bottom',
			once: true
		});
	}, 500);

const Init = module => {
	const init = () => {
		fadeLoader();
		topMenu();
		Footer();
		Radio();
		module();
	};
	
	window.addEventListener('load', init);
};

export default Init;
