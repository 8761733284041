const Alert = (() => {
	const message = document.querySelector('#js-flash-message');
	
	if (message) {
		setTimeout(function () {
			message.remove();
		}, 10000);
	}
})();

export default Alert;