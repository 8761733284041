import colorbox from 'jquery-colorbox';
import './Homepage.scss';

// import Init from '../../../extendable/Init';
import Slider from '../../../components/Slider/Slider';
import SupportBox from '../../../components/SupportBox/SupportBox';
import Join from '../../../components/Join/Join';
import NewsList from '../NewsList/NewsList';

const Homepage = () => {
	$('.colorbox').colorbox({
		maxWidth: '95%',
		maxHeight: '95%',
		scrolling: false,
		fixed: true,
		title: '',
	});
};

Init(Homepage);
