import 'owl.carousel.es6';

import './Slider.scss';

const Slider = (() => {
	$(document).ready(function () {
		const slider = document.getElementsByClassName('fullScreenSlider')[0];
		const sliderInfo = document.getElementById('js-slider-info');
		let dots = null;
		const autoplay = 5000;
		
		const prev = "<i class='fa fa-angle-left'></i>";
		const next = "<i class='fa fa-angle-right'></i>";
		
		const changeSlider = event => {
			let w = 0;
			if (sliderInfo) {
				sliderInfo.innerText = `${
					event.page.index + 1 === 0 ? 1 : event.page.index + 1
				} / ${event.item.count}`;
			}
			
			let progressbar = document.querySelector('#active-progress');
			
			if (progressbar) {
				progressbar.remove();
			}
			
			if (dots) {
				const activeDot = dots.getElementsByClassName('active')[0];
				const progress = document.createElement('div');
				progress.setAttribute('id', 'active-progress');
				activeDot.appendChild(progress);
				progressbar = document.querySelector('#active-progress');
				
				const incr = () => {
					if (w >= 100) {
						clearInterval(interval);
					} else {
						w += 25;
					}
					progressbar.style.width = `${w}%`;
				};
				
				const interval = setInterval(incr, 1000);
			}
		};
		
		const setDots = event => {
			dots = document.getElementsByClassName('owl-dots')[0];
			changeSlider(event);
		};
		
		const carousel = $('.fullScreenSlider').owlCarousel({
			loop: true,
			margin: 0,
			items: 1,
			// animateIn: 'fadeIn',
			// animateOut: 'fadeOut',
			autoplayTimeout: autoplay,
			mouseDrag: false,
			// autoplayHoverPause: true,
			autoplay: true,
			dotsContainer: '#carousel-custom-dots',
			navContainer: '#carousel-custom-nav',
			responsiveClass: true,
			navText: [prev, next],
			onChanged: event => changeSlider(event),
			onInitialized: event => {
				setDots(event);
			},
			dots: true,
			nav: true,
		});
	});
})();

export default Slider;
